<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card >
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-prompt
              class=""
              title="Livraison client"
              accept-text= "Valider"
              cancelText="Annuler"
              @accept="validationLivraisonClient"
              @cancel="annulerLivraisonClient"
              :active.sync="activePromptValideLivraisonVenteClient"
            >
              <div class="vx-row">
                <div class="vx-col w-full mt-4">
                  <label for="dateLivraisonClient">Date livraison</label>
                  <datepicker :language="$vs.rtl ? langHe : langFr" name="dateLivraisonClient" v-model="dateLivraisonClient"></datepicker>
                </div>
                <div class="vx-col w-full mt-4">
                  <label for="etiquetteEtuis">Ref. etuis</label>
                  <div class="vx-row">
                    <div class="vx-col md:w-2/3 sm:w-full xs:w-full">
                      <vs-input name="etiquetteEtuis" class="w-full mt-3" placeholder="etiquette" :value="etiquetteEtuis" @change.native="etiquetteEtuis=$event.target.value"></vs-input>
                    </div>
                    <div class="vx-col md:w-1/3 sm:w-full xs:w-full">
                      <vs-input type="number" name="qteEtuis" class="w-full mt-3" placeholder="Qte etuis" :value="qteEtuis" @change.native="qteEtuis=$event.target.value"></vs-input>
                    </div>
                  </div>
                </div>
                 <div class="vx-col w-full mt-4">
                  <label for="etiquetteEssuie">Ref. Essuie</label>
                  <div class="vx-row">
                    <div class="vx-col md:w-2/3 sm:w-full xs:w-full">
                      <vs-input name="etiquetteEssuie" class="w-full mt-3" placeholder="etiquette" :value="etiquetteEssuie" @change.native="etiquetteEssuie=$event.target.value"></vs-input>
                    </div>
                    <div class="vx-col md:w-1/3 sm:w-full xs:w-full">
                      <vs-input type="number" name="qteEssuie" v-validate="'required'" class="w-full mt-3" placeholder="Qte essuie" :value="qteEssuie" @change.native="qteEssuie=$event.target.value"></vs-input>
                    </div>
                  </div>
                </div>
                 <div class="vx-col w-full mt-4">
                  <label for="etiquetteSacLivraison">Ref. Sac de livraison</label>
                  <div class="vx-row">
                    <div class="vx-col md:w-2/3 sm:w-full xs:w-full">
                      <vs-input name="etiquetteSacLivraison" class="w-full mt-3" placeholder="etiquette" :value="etiquetteSacLivraison" @change.native="etiquetteSacLivraison=$event.target.value"></vs-input>
                    </div>
                    <div class="vx-col md:w-1/3 sm:w-full xs:w-full">
                      <vs-input type="number" name="qteSacLivraison" v-validate="'required'" class="w-full mt-3" placeholder="Qte sac" :value="qteSacLivraison" @change.native="qteSacLivraison=$event.target.value"></vs-input>
                    </div>
                  </div>
                </div>
              </div>
              <br/>
            </vs-prompt>
            <vs-prompt
              class="calendar-event-dialog"
              title="Commande verre"
              accept-text= "Valider"
              @accept="PassCommandeVerre"
              :is-valid="validForm"
              :active.sync="activePromptCommandeVerre">

                <table class="w-full"  cellpadding="0" cellspacing="0">
                  <thead>
                    <tr>
                      <th class="customth" style="border: none"></th>
                      <th class="customth" style="border-width: 0px;">Sph</th>
                      <th class="customth" style="border-width: 0px;">Cyl</th>
                      <th class="customth" style="border-width: 0px;">Axe</th>
                      <th class="customth" style="border-width: 0px;">Add</th>
                      <th class="customth" style="border-width: 0px;">Diam</th>
                    </tr>
                    <tr>
                      <th class="customth" style="border-width: 0px;">OD</th>
                      <th class="customth" style="border-width: 0px;">{{SphVD}}</th>
                      <th class="customth" style="border-width: 0px;">{{CylVD}}</th>
                      <th class="customth" style="border-width: 0px;">{{AxeVD}}°</th>
                      <th class="customth" style="border-width: 0px;">{{AddVD}}</th>
                      <th class="customth" style="border-width: 0px;"></th>
                    </tr>
                    <tr>
                      <th class="customth" style="border-width: 0px;">OG</th>
                      <th class="customth" style="border-width: 0px;">{{SphVG}}</th>
                      <th class="customth" style="border-width: 0px;">{{CylVG}}</th>
                      <th class="customth" style="border-width: 0px;">{{AxeVG ? `${AxeVG}°` : ''}}</th>
                      <th class="customth" style="border-width: 0px;">{{AddVG}}</th>
                      <th class="customth" style="border-width: 0px;"></th>
                    </tr>
                  </thead>
                </table>
                <vs-tabs class="mt-1">
                  <vs-tab v-if="RefVerD1 || RefVerG1" label="Commande 1">
                    <div class="my-4">
                      <small class="date-label">Date</small>
                      <datepicker :language="$vs.rtl ? langHe : langFr" name="dateCommandeVerre" v-model="dateCommandeVerre1"></datepicker>
                    </div>
                    <div class="vx-col w-ful">
                      <vs-select label="Fournisseur" class="w-full mt-4"  autocomplete v-model="fournVerre1">
                        <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                      </vs-select>
                    </div>
                    <div class="vx-col w-ful mt-5">
                      <vs-select label="Type de verre" class="w-full mt-4"  autocomplete v-model="typeVerre1">
                        <vs-select-item value="Simple foyer" text="Simple foyer" />
                        <vs-select-item value="Double foyer" text="Double foyer" />
                        <vs-select-item value="Progessif" text="Progessif" />
                      </vs-select>
                    </div>
                    <div class="vx-col w-full">
                      <vs-input name="event-name" v-validate="'required'" class="w-full mt-5" label="Verre Droit" v-model="RefVerD1"></vs-input>
                    </div>
                    <div class="vx-col w-full">
                      <vs-input name="event-name" v-validate="'required'" class="w-full mt-5" label="Verre gauche" v-model="RefVerG1"></vs-input>
                    </div>
                  </vs-tab>
                  <vs-tab v-if="RefVerD2 || RefVerG2" label="Commande 2">
                    <div class="my-4">
                      <small class="date-label">Date</small>
                      <datepicker :language="$vs.rtl ? langHe : langFr" name="dateCommandeVerre" v-model="dateCommandeVerre2"></datepicker>
                    </div>
                    <div class="vx-col w-ful">
                      <vs-select label="Fournisseur" class="w-full mt-4"  autocomplete v-model="fournVerre2">
                        <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                      </vs-select>
                    </div>
                    <div class="vx-col w-ful mt-5">
                      <vs-select label="Type de verre" class="w-full mt-4"  autocomplete v-model="typeVerre2">
                        <vs-select-item value="Simple foyer" text="Simple foyer" />
                        <vs-select-item value="Double foyer" text="Double foyer" />
                        <vs-select-item value="Progessif" text="Progessif" />
                      </vs-select>
                    </div>
                    <div class="vx-col w-full">
                      <vs-input name="event-name" v-validate="'required'" class="w-full mt-5" label="Verre Droit" v-model="RefVerD2"></vs-input>
                    </div>
                    <div class="vx-col w-full">
                      <vs-input name="event-name" v-validate="'required'" class="w-full mt-5" label="Verre gauche" v-model="RefVerG2"></vs-input>
                    </div>
                  </vs-tab>
                  <vs-tab v-if="RefVerD3 || RefVerG3" label="Commande 3">
                    <div class="my-4">
                      <small class="date-label">Date</small>
                      <datepicker :language="$vs.rtl ? langHe : langFr" name="dateCommandeVerre" v-model="dateCommandeVerre3"></datepicker>
                    </div>
                    <div class="vx-col w-ful">
                      <vs-select label="Fournisseur" class="w-full mt-4"  autocomplete v-model="fournVerre3">
                        <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                      </vs-select>
                    </div>
                    <div class="vx-col w-ful mt-5">
                      <vs-select label="Type de verre" class="w-full mt-4"  autocomplete v-model="typeVerre3">
                        <vs-select-item value="Simple foyer" text="Simple foyer" />
                        <vs-select-item value="Double foyer" text="Double foyer" />
                        <vs-select-item value="Progessif" text="Progessif" />
                      </vs-select>
                    </div>
                    <div class="vx-col w-full">
                      <vs-input name="event-name" v-validate="'required'" class="w-full mt-5" label="Verre Droit" v-model="RefVerD3"></vs-input>
                    </div>
                    <div class="vx-col w-full">
                      <vs-input name="event-name" v-validate="'required'" class="w-full mt-5" label="Verre gauche" v-model="RefVerG3"></vs-input>
                    </div>

                  </vs-tab>
                </vs-tabs>

            </vs-prompt>
            <vs-prompt
              class="calendar-event-dialog"
              title="Verre commandée"
              accept-text= "Validé la livraison"
              cancelText="Retourné la commande"
              @accept="validLivraisonCommandeVerre"
              @cancel="RetourCommandeVerre"
              :is-valid="validForm"
              :active.sync="activePromptValideCommandeVerre">

                <table class="w-full"  cellpadding="0" cellspacing="0">
                  <thead>
                    <tr>
                      <th class="customth" style="border: none"></th>
                      <th class="customth" style="border-width: 0px;">Sph</th>
                      <th class="customth" style="border-width: 0px;">Cyl</th>
                      <th class="customth" style="border-width: 0px;">Axe</th>
                      <th class="customth" style="border-width: 0px;">Add</th>
                      <th class="customth" style="border-width: 0px;">Diam</th>
                    </tr>
                    <tr>
                      <th class="customth" style="border-width: 0px;">OD</th>
                      <th class="customth" style="border-width: 0px;">{{SphVD}}</th>
                      <th class="customth" style="border-width: 0px;">{{CylVD}}</th>
                      <th class="customth" style="border-width: 0px;">{{AxeVD}}°</th>
                      <th class="customth" style="border-width: 0px;">{{AddVD}}</th>
                      <th class="customth" style="border-width: 0px;"></th>
                    </tr>
                    <tr>
                      <th class="customth" style="border-width: 0px;">OG</th>
                      <th class="customth" style="border-width: 0px;">{{SphVG}}</th>
                      <th class="customth" style="border-width: 0px;">{{CylVG}}</th>
                      <th class="customth" style="border-width: 0px;">{{AxeVG ? `${AxeVG}°` : ''}}</th>
                      <th class="customth" style="border-width: 0px;">{{AddVG}}</th>
                      <th class="customth" style="border-width: 0px;"></th>
                    </tr>
                  </thead>
                </table>
                <vs-tabs>
                  <vs-tab label="Commande 1">
                    <div class="vx-col w-full">
                      <small class="date-label">Date livraison</small>
                      <datepicker :language="$vs.rtl ? langHe : langFr" name="dateLivraisonVerre1" v-model="dateLivraisonVerre1"></datepicker>
                    </div>
                    <div class="vx-col w-full mt-5">
                      <span class="font-black">Fournisseur:</span> {{showFournisseurName(fournVerreId1)}}
                    </div>
                    <div class="vx-col w-full mt-5">
                      <span class="font-black">Type de verre: </span> {{typeVerre1}}
                    </div>
                    <div class="vx-col w-full mt-5">
                      <span class="font-black">Verre Droit: </span> {{libelleVerreD1}}
                    </div>
                    <div class="vx-col w-full mt-5">
                      <span class="font-black">Verre Gauche: </span> {{libelleVerreG1}}
                    </div>
                    <br><br><br><br><br><br><br>
                  </vs-tab>
                  <vs-tab label="Commande 2">
                    <div class="vx-col w-full">
                      <small class="date-label">Date livraison</small>
                      <datepicker :language="$vs.rtl ? langHe : langFr" name="dateLivraisonVerre2" v-model="dateLivraisonVerre2"></datepicker>
                    </div>
                    <div class="vx-col w-full mt-5">
                      <span class="font-black">Fournisseur:</span> {{showFournisseurName(fournVerreId2)}}
                    </div>
                    <div class="vx-col w-full mt-5">
                      <span class="font-black">Type de verre:</span> {{typeVerre2}}
                    </div>
                    <div class="vx-col w-full mt-5">
                      <span class="font-black">Verre Droit: </span> {{libelleVerreD2}}
                    </div>
                    <div class="vx-col w-full mt-5">
                      <span class="font-black">Verre Gauche: </span> {{libelleVerreG2}}
                    </div>
                    <br><br><br><br><br><br><br>
                  </vs-tab>
                  <vs-tab label="Commande 3">
                    <div class="vx-col w-full">
                      <small class="date-label">Date livraison</small>
                      <datepicker :language="$vs.rtl ? langHe : langFr" name="dateLivraisonVerre3" v-model="dateLivraisonVerre3"></datepicker>
                    </div>
                    <div class="vx-col w-full">
                      <span class="font-black">Fournisseur:</span> {{showFournisseurName(fournVerreId3)}}
                    </div>
                    <div class="vx-col w-full">
                      <span class="font-black">Type de verre:</span> {{typeVerre3}}
                    </div>
                    <div class="vx-col w-full">
                      <span class="font-black">Verre Droit: </span> {{libelleVerreD3}}
                    </div>
                    <div class="vx-col w-full">
                      <span class="font-black">Verre Gauche: </span> {{libelleVerreG3}}
                    </div>
                    <br><br><br><br><br><br><br>
                  </vs-tab>
                </vs-tabs>

            </vs-prompt>
            <vs-table v-model="selected" pagination stripe max-items="50" search :data="clientsData">
              <template slot="header">
                <!-- ACTION - DROPDOWN -->
                <vs-dropdown vs-trigger-click class="cursor-pointer">

                  <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                    <span class="mr-2 leading-none">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Supprimer</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>CSV</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown>
                <vs-button v-if="activeUserInfos.privileges.find(item => item === 'commande')&&activeUserInfos.privileges.find(item => item === 'create')" class="mb-4 ml-5 mr-5 md:mb-0" @click="$router.push('/apps/commandeclient/add')">Nouvelle vente</vs-button>
                <vs-select v-if="principale" placeholder="Officine" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="idOfficine" @change="CurrentOfficineSelectClient()">
                  <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
                </vs-select>
                <vue-simple-suggest
                  pattern="\w+"
                  v-model="model"
                  :list="getClientList"
                  :max-suggestions="10"
                  :min-length="3"
                  :debounce="200"
                  :filter-by-query="false"
                  :prevent-submit="true"
                  :controls="{
                    selectionUp: [38, 33],
                    selectionDown: [40, 34],
                    select: [13, 36],
                    hideList: [27, 35]
                  }"
                  :mode="mode"
                  :nullable-select="true"
                  ref="suggestComponent"
                  placeholder="Search information..."
                  value-attribute="id"
                  display-attribute="fullname"
                  @select="onSuggestSelect">

                  <div class="g">
                    <input type="text" placeholder="Recherche Client: Nom prénoms">
                  </div>

                  <template slot="misc-item-above" slot-scope="{ suggestions, query }">
                    <div class="misc-item">
                      <span>Vous recherchez '{{ query }}'.</span>
                    </div>

                    <template v-if="suggestions.length > 0">
                      <div class="misc-item">
                        <span>{{ suggestions.length }} suggestions sont affichées...</span>
                      </div>
                      <hr>
                    </template>

                    <div class="misc-item" v-else-if="!loading">
                      <span>Aucun résultat</span>
                    </div>
                  </template>

                  <div slot="suggestion-item" slot-scope="scope" :title="`${scope.suggestion.Nom} ${scope.suggestion.Prenoms}`">
                    <div class="text">
                      <span v-html="boldenSuggestion(scope)"></span>
                    </div>
                  </div>

                  <div class="misc-item" slot="misc-item-below" slot-scope="{}" v-if="loading">
                    <span>Chargement...</span>
                  </div>
                </vue-simple-suggest>
              </template>
              <template slot="thead">
                <vs-th sort-key="numero">
                  N°
                </vs-th>

                <vs-th sort-key="Nom">
                  Nom client
                </vs-th>
                <vs-th sort-key="Prenoms">
                  Prénoms client
                </vs-th>
                <vs-th sort-key="adresse">
                  Adresse client
                </vs-th>
                <vs-th sort-key="SimpleFoyer">
                  Email
                </vs-th>
                <vs-th sort-key="DoubleFoyer">
                  Contact
                </vs-th>

              </template>

              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                  <template v-if="tr.commandes.length > 0">
                    <vs-td :data="data[indextr]">
                      {{indextr + 1}}
                    </vs-td>
                    <vs-td :data="data[indextr].Nom">
                      {{data[indextr].Nom}}
                    </vs-td>
                    <vs-td :data="data[indextr].Prenoms">
                      {{data[indextr].Prenoms}}
                    </vs-td>
                    <vs-td :data="data[indextr].Adresse.adresse">
                      {{data[indextr].Adresse.adresse}}
                    </vs-td>
                    <vs-td :data="data[indextr].Adresse.email">
                      {{data[indextr].Adresse.email}}
                    </vs-td>
                    <vs-td :data="data[indextr].Adresse.mobile">
                      {{data[indextr].Adresse.mobile}}
                    </vs-td>

                    <template class="expand-user" slot="expand">
                      <div class="con-expand-users w-full">
                          <vs-th sort-key="numero">
                            N°
                          </vs-th>
                          <vs-th sort-key="RefCmmd">
                            Ref vente
                          </vs-th>

                          <vs-th sort-key="DateCommd">
                            Date vente
                          </vs-th>
                          <vs-th sort-key="Prescripteur">
                            Prescripteur
                          </vs-th>
                          <vs-th sort-key="TotalCmd">
                            Total vente
                          </vs-th>
                          <vs-th sort-key="PartClient">
                            Part client
                          </vs-th>
                          <vs-th sort-key="PartAssu">
                            Part assu.
                          </vs-th>
                          <vs-th sort-key="CmdeAuFourVerre">
                            cmde verre
                          </vs-th>
                          <vs-th sort-key="CmdeTSalleMontage">
                            Montage
                          </vs-th>
                          <vs-th sort-key="ComdeLivreClient">
                            Livrer
                          </vs-th>
                          <vs-th sort-key="active">
                            Solde
                          </vs-th>
                          <vs-th>
                            Actions
                          </vs-th>
                        <vs-tr :data="tr2" :key="indextr2" v-for="(tr2, indextr2) in data[indextr].commandes">
                          <vs-td>
                            {{indextr2+1}}
                          </vs-td>
                          <vs-td>
                            {{tr2.RefCmmd}}
                          </vs-td>
                          <vs-td :data="tr2.DateCommd">
                            {{tr2.DateCommd | moment("calendar", "July 10 2011")}}
                          </vs-td>
                          <vs-td>
                            {{ getPrescripteurName(tr2.Idprescripteur) }}
                          </vs-td>
                          <vs-td>
                            {{moneyFormatter(totalNet(tr2.PrixMont1, tr2.PrixVerG1, tr2.PrixVerD1, tr2.PrixMont2, tr2.PrixVerG2, tr2.PrixVerD2, tr2.PrixMont3, tr2.PrixVerG3, tr2.PrixVerD3, tr2.RemiseCom)) }}
                          </vs-td>
                          <vs-td>
                            <p class="PartClient">{{ moneyFormatter(tr2.PartClient) }}</p>
                          </vs-td>

                          <vs-td>
                            <p class="PartClient">{{ moneyFormatter(tr2.PartAssu) }}</p>
                          </vs-td>
                          <vs-td>
                            <template v-if="tr2.CmdeAuFourVerre">
                              <template v-if="tr2.verreLivrer">
                                <vs-chip transparent color="primary">
                                  Reçu
                                </vs-chip>
                              </template>
                              <template v-else>
                                <vs-chip transparent color="warning">
                                  <span>Encours</span>
                                </vs-chip>
                              </template>
                            </template>
                            <template v-else>
                              <vs-chip transparent color="danger" >
                                <span @click="commandeFournVerr(tr, tr2)">Non passée</span>
                              </vs-chip>
                            </template>
                          </vs-td>
                          <vs-td>
                            <template v-if="tr2.CmdeTSalleMontage">
                              <template v-if="tr2.CmdeMontageFin">
                                <template v-if="tr2.Monteur!=='' && tr2.montageValiderPar">
                                  <vs-chip transparent color="primary">
                                    Terminé
                                  </vs-chip>
                                </template>
                                <template v-else>
                                  <vs-chip transparent color="success">
                                    Verification
                                  </vs-chip>
                                </template>
                              </template>
                              <template v-else>
                                <vs-chip transparent color="warning">
                                  Encours
                                </vs-chip>
                              </template>
                            </template>
                            <template v-else>
                              <vs-chip transparent color="danger">
                                Pas débuter
                              </vs-chip>
                            </template>
                          </vs-td>
                          <vs-td>
                            <template v-if="tr2.ComdeLivreClient">
                              <vs-chip transparent color="primary">
                                Oui
                              </vs-chip>
                            </template>
                            <template v-else>
                              <vs-chip transparent color="danger">
                                <span @click="ShowValidationLivraisonClient(tr, tr2)">Non</span>
                              </vs-chip>
                            </template>
                          </vs-td>
                          <vs-td>
                            <p class="PartClient">{{ solde(tr2) }}</p>
                          </vs-td>
                          <vs-td :data="data[indextr]._id">
                            <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                              <feather-icon title="Réglèment commande" icon="SendIcon" svgClasses="w-5 h-5 mr-4 hover:text-primary stroke-current" @click.stop="reglementCmmd(tr._id, tr2.RefCmmd)" />
                              <feather-icon title="Voir" icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="showCommande(tr._id, tr2.RefCmmd)" />
                              <feather-icon title="Modifier" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editData(tr._id, tr2.RefCmmd)" />
                              <!-- <feather-icon title="Supprimer" icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(tr._id, tr2.RefCmmd)" /> -->
                            </div>
                          </vs-td>
                        </vs-tr>
                      </div>
                    </template>
                  </template>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'
import Datepicker from 'vuejs-datepicker'
import { en, he, fr } from 'vuejs-datepicker/src/locale'
import axios from '@/axios.js'
export default {
  components: {VueSimpleSuggest, Datepicker},
  data () {
    return {
      model: null,
      mode: 'input',
      loading: false,
      chosen: '',
      IdUser: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo'))._id : '',
      selected:[],
      ordonnances:[],
      ordonnanceId: '',
      idOfficine: null,
      activeUserInfos: null,
      clientId: null,
      currentRefOrd: null,

      activePromptCommandeVerre: false,
      RefCmmd: '',
      fournVerre1: null,
      fournVerre2: null,
      fournVerre3: null,
      RefVerG1: null,
      RefVerD1: null,
      RefVerG2: null,
      RefVerD2: null,
      RefVerG3: null,
      RefVerD3: null,

      dateCommandeVerre1: null,
      dateCommandeVerre2: null,
      dateCommandeVerre3: null,

      idClient: null,
      typeVerre1: '',
      typeVerre2: '',
      typeVerre3: '',

      SphVD: '',
      CylVD: '',
      AxeVD: '',
      AddVD: '',
      SphVG: '',
      CylVG: '',
      AxeVG: '',
      AddVG: '',

      activePromptValideCommandeVerre: false,
      fournVerreId1: null,
      commandeVerre1: null,
      libelleVerreD1: '',
      libelleVerreG1: '',

      fournVerreId2: null,
      commandeVerre2: null,
      libelleVerreD2: '',
      libelleVerreG2: '',

      fournVerreId3: null,
      commandeVerre3: null,
      libelleVerreD3: '',
      libelleVerreG3: '',

      dateLivraisonVerre1: null,
      dateLivraisonVerre2: null,
      dateLivraisonVerre3: null,

      activePromptValideLivraisonVenteClient: false,
      dateLivraisonClient: null,
      etiquetteEtuis: null,
      qteEtuis: null,
      etiquetteEssuie: null,
      qteEssuie: null,
      etiquetteSacLivraison: null,
      qteSacLivraison: null,
      accessoireLivraisonClient: [],

      langHe: he,
      langEn: en,
      langFr: fr
    }
  },
  computed: {
    validForm () {
      return this.RefVerG1 !== ''
    },
    clientsData () {
      const commandes = []
      const clients = this.$store.state.client.clients

      clients.forEach(client => {
        if (client.commandes.length > 0) {
          commandes.push(client)
        }
      })
      return commandes
    },
    officines () {
      return this.$store.state.officine.officines
    },
    prescripteurs () {
      return this.$store.state.prescripteur.prescripteurs
    },
    principale () {
      return this.$store.getters['officine/officineIsPrincipale'](JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null)
    },
    fournisseur_verres_data () {
      return this.$store.state.verre.fournisseurs_verres
    }
  },

  methods: {
    getClientList (inputValue) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/client-full-search/${this.idOfficine}/${inputValue}`)
          .then((response) => {
            /* const fields = ['Nom', 'Prenoms'] */
            const result = response.data
            response.data.forEach((part, index) => {
              console.log('part', part)
              response.data[index].fullname = `${part.Nom} ${part.Prenoms}`
            })
            console.log('result', result)
            resolve(result)
            /* resolve(response) */
          })
          .catch((err) => {
            reject(err)
          })

      })
    },
    onSuggestSelect (suggest) {
      this.selected = suggest
      this.$store.commit('client/ADD_SEARCH_CLIENT', suggest)
    },
    boldenSuggestion (scope) {
      if (!scope) return scope
      const { suggestion, query } = scope
      const result = this.$refs.suggestComponent.displayProperty(suggestion)
      if (!query) return result
      const texts = query.split(/[\s-_/\\|.]/gm).filter(t => !!t) || ['']
      return result.replace(new RegExp(`(.*?)(${  texts.join('|')  })(.*?)`, 'gi'), '$1<b>$2</b>$3')
    },
    totalNet (PrixMont1, PrixVerG1, PrixVerD1, PrixMont2, PrixVerG2, PrixVerD2, PrixMont3, PrixVerG3, PrixVerD3, RemiseCom) {
      return (((+PrixMont1 ? +PrixMont1 : 0) + (+PrixVerG1 ? +PrixVerG1 : 0) + (+PrixVerD1 ? +PrixVerD1 : 0) + (+PrixMont2 ? +PrixMont2 : 0) + (+PrixVerG2 ? +PrixVerG2 : 0) + (+PrixVerD2 ? +PrixVerD2 : 0) + (+PrixMont3 ? +PrixMont3 : 0) + (+PrixVerG3 ? +PrixVerG3 : 0) + (+PrixVerD3 ? +PrixVerD3 : 0)) - (RemiseCom ? RemiseCom : 0))
    },
    solde (commande) {
      const totalCmmd = commande.PartClient + commande.PartAssu

      const totalRegl = commande.reglements.reduce((acc, regl) => {
        return acc + +regl.Montant
      }, 0)

      return  this.moneyFormatter((+totalCmmd - +totalRegl))
    },
    moneyFormatter (montant) {
      /* Formatage du calcul */
      const val = (montant / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    reglementCmmd (clientId, RefCmmd) {
      this.$router.push(`/apps/caisse/${clientId}/${RefCmmd}`).catch((err) => { console.log(err) })
    },
    showCommande (clientId, RefCmmd) {
      this.$router.push(`/apps/commandeclient/detail/${clientId}/${RefCmmd}`).catch((err) => { console.log(err) })
    },
    editData (clientId, RefCmmd) {
      this.$router.push(`/apps/commandeclient/edit/${clientId}/${RefCmmd}`).catch((err) => { console.log(err) })
    },
    showFournisseurName (id) {
      return id ? this.$store.getters['verre/showFournisseurName'](id) : ''
    },
    validCommandeVerreForm (data1, data2) {
      this.idClient = data1._id

      this.RefCmmd = data2.RefCmmd
      this.SphVD = data2.SphVD
      this.CylVD = data2.CylVD
      this.AxeVD = data2.AxeVD
      this.AddVD = data2.AddVD
      this.SphVG = data2.SphVG
      this.CylVG = data2.CylVG
      this.AxeVG = data2.AxeVG
      this.AddVG = data2.AddVG

      this.typeVerre1 = data2.typeVerre1
      this.commandeVerre1 = data2.commandeVerre1
      this.fournVerreId1 = data2.fournVerreId1
      this.libelleVerreD1 = data2.libelleVerreD1
      this.libelleVerreG1 = data2.libelleVerreG1

      this.typeVerre2 = data2.typeVerre2
      this.commandeVerre2 = data2.commandeVerre2
      this.fournVerreId2 = data2.fournVerreId2
      this.libelleVerreD2 = data2.libelleVerreD2
      this.libelleVerreG2 = data2.libelleVerreG2

      this.typeVerre3 = data2.typeVerre3
      this.commandeVerre3 = data2.commandeVerre3
      this.fournVerreId3 = data2.fournVerreId3
      this.libelleVerreD3 = data2.libelleVerreD3
      this.libelleVerreG3 = data2.libelleVerreG3

      this.activePromptValideCommandeVerre = true
    },
    validLivraisonCommandeVerre () {
      const payload = {
        RefCmmd: this.RefCmmd,
        dateLivraisonVerre1: new Date(new Date(this.dateLivraisonVerre1)).toISOString().split('T')[0],
        dateLivraisonVerre2: new Date(new Date(this.dateLivraisonVerre2)).toISOString().split('T')[0],
        dateLivraisonVerre3: new Date(new Date(this.dateLivraisonVerre3)).toISOString().split('T')[0],
        commandeVerre1: this.commandeVerre1,
        commandeVerre2: this.commandeVerre2,
        commandeVerre3: this.commandeVerre3,
        id: this.idClient,
        IdUser: this.IdUser
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('client/valideLivraisonCommandeFournVerre', payload)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((err) => {
          console.error(err)
          this.$vs.loading.close()
        })


    },
    RetourCommandeVerre () {
      alert('ok test')
    },
    commandeFournVerr (data1, data2) {
      this.idClient = data1._id
      this.RefCmmd = data2.RefCmmd

      this.fournVerre1 = data2.fournVerreId1
      this.RefVerG1 = data2.RefVerG1
      this.RefVerD1 = data2.RefVerD1

      this.fournVerre2 = data2.fournVerreId2
      this.RefVerG2 = data2.RefVerG2
      this.RefVerD2 = data2.RefVerD2

      this.fournVerre3 = data2.fournVerreId3
      this.RefVerG3 = data2.RefVerG3
      this.RefVerD3 = data2.RefVerD3

      this.SphVD = data2.SphVD
      this.CylVD = data2.CylVD
      this.AxeVD = data2.AxeVD
      this.AddVD = data2.AddVD
      this.SphVG = data2.SphVG
      this.CylVG = data2.CylVG
      this.AxeVG = data2.AxeVG
      this.AddVG = data2.AddVG

      this.activePromptCommandeVerre = true
    },
    PassCommandeVerre () {
      const payload = {
        idClient: this.idClient,
        IdUser: this.IdUser,

        RefCmmd: this.RefCmmd,
        SphVD: this.SphVD,
        SphVG: this.SphVG,
        CylVD: this.CylVD,
        CylVG: this.CylVG,
        AxeVD: this.AxeVD,
        AxeVG: this.AxeVG,
        AddVD: this.AddVD,
        AddVG: this.AddVG,

        fournVerre1: this.fournVerre1,
        typeVerre1: this.typeVerre1,
        RefVerD1: this.RefVerD1,
        RefVerG1: this.RefVerG1,
        dateCommandeVerre1: new Date(new Date(this.dateCommandeVerre1)).toISOString().split('T')[0],

        fournVerre2: this.fournVerre2,
        typeVerre2: this.typeVerre2,
        RefVerD2: this.RefVerD2,
        RefVerG2: this.RefVerG2,
        dateCommandeVerre2: new Date(new Date(this.dateCommandeVerre2)).toISOString().split('T')[0],

        fournVerre3: this.fournVerre3,
        typeVerre3: this.typeVerre3,
        RefVerD3: this.RefVerD3,
        RefVerG3: this.RefVerG3,
        dateCommandeVerre3: new Date(new Date(this.dateCommandeVerre3)).toISOString().split('T')[0]
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('client/valideCommandeFournVerre', payload)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((err) => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    ShowValidationLivraisonClient (data1, data2) {

      if (data2.CmdeTSalleMontage) {
        if (data2.CmdeMontageFin) {
          if (data2.Monteur !== '' && data2.montageValiderPar) {
            this.idClient = data1._id
            this.RefCmmd = data2.RefCmmd
            this.activePromptValideLivraisonVenteClient = true
          } else {
            this.$vs.dialog({
              type: 'alert',
              color: 'danger',
              title: 'Error Livraison client',
              text: 'Impossible de valider la livraison client. Veillez vérifié que le montage des lunettes est bien terminé!'
            })
          }
        } else {
          this.$vs.dialog({
            type: 'alert',
            color: 'danger',
            title: 'Error Livraison client',
            text: 'Impossible de valider la livraison client. Veillez vérifié que le montage des lunettes est bien terminé!'
          })
        }
      } else {
        this.$vs.dialog({
          type: 'alert',
          color: 'danger',
          title: 'Error Livraison client',
          text: 'Impossible de valider la livraison client. Veillez vérifié que la commande de verre ou le montage des lunettes est bien terminé!'
        })
      }
    },
    validationLivraisonClient () {
      /* RefCmmd: this.RefCmmd */
      /* idClient: this.idClient */
      /* accessoireLivraisonClient: this.accessoireLivraisonClient */
      const payload = {
        RefCmmd: this.RefCmmd,
        idClient: this.idClient,
        dateLivraisonClient: this.dateLivraisonClient,
        qteEtuis: this.qteEtuis,
        qteEssuie: this.qteEssuie,
        qteSacLivraison: this.qteSacLivraison,
        etiquetteEtuis: this.etiquetteEtuis,
        etiquetteEssuie: this.etiquetteEssuie,
        etiquetteSacLivraison: this.etiquetteSacLivraison
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('client/validationLivraisonClient', payload)
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
        })
        .catch((err) => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    annulerLivraisonClient () {
      this.RefCmmd = ''
      this.idClient = null
      this.dateLivraisonClient = null
      this.qteEtuis = null
      this.qteEssuie = null
      this.qteSacLivraison = null
    },
    confirmDeleteRecord (clientId, RefCmmd) {
      this.clientId = clientId
      this.currentRefOrd = RefCmmd

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Ordonnance suppression',
        text: `Vous allez supprimer la commande "${RefCmmd}"`,
        acceptText: 'Supprimer',
        accept: this.deleteData
      })
    },
    deleteData () {
      const payload = {
        clientId: this.clientId,
        refOrd: this.currentRefOrd
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('client/removeOrdonnance', payload)
        .then(() => {
          this.$vs.notify({
            title: 'Ordonnance Supprimer',
            text: 'supprimer!',
            iconPack: 'feather',
            icon: 'icon-trash',
            color: 'danger'
          })
          this.showDeleteSuccess()
          this.$router.push(`/apps/client/client-view/${this.clientId}`).catch((err) => { console.log(err) })
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })

    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'Suppression ordonnance',
        text: 'Supprimer'
      })
    },
    getPrescripteurName (id) {
      const prescripteur = this.prescripteurs.find((p) => p._id === id) ? this.prescripteurs.find((p) => p._id === id) : ''
      return prescripteur ? `${prescripteur.NomPresc ? prescripteur.NomPresc : ''} ${prescripteur.PrenomPresc ? prescripteur.PrenomPresc : ''}` : ''
    },
    getPrescripteurs () {
      this.$store.dispatch('prescripteur/getPrescripteurs')
        .then(() => {

        })
        .catch(err => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    CurrentOfficineSelectClient () {
      this.getClientsOfficine(this.idOfficine)
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.idOfficine = this.activeUserInfos.IdOfficine
      this.getClientsOfficine(this.idOfficine)
    },
    getClientsOfficine (id) {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('client/getClientsOfficine', id)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((err) => {
          console.log(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getFournisseursVerres () {
      this.$store.dispatch('verre/getFournisseursVerres')
        .catch((error) => { console.log(error) })
    }
  },
  created () {
    if (!(this.$store.state.officine.officines.length > 0)) this.getOfficines()
    if (!(this.$store.state.prescripteur.prescripteurs.length > 0)) this.getPrescripteurs()
    this.activeUserInfo()
    if (!(this.$store.state.verre.fournisseurs_verres.length > 0)) this.getFournisseursVerres()
  }
}
</script>
<style>
.customtable, .customth, .customtd {
  border: 0.5px solid black;
  border-collapse: collapse;
  /* font-family: 'Times New Roman'; */
  font-size: 12px;
}

.customtd {
  padding: 5px;
}
</style>

